<template>
  <div class="px-5">
    <Form
      title="Editar entrenador"
      ref="coachForm"
      :deletable="true"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: true,
      form: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    toggleVisibility() {
      this.$store
        .dispatch("global/changeStatus", {
          payload: {
            id: this.$refs.coachForm.form.id,
            status: !this.$refs.coachForm.visible ? 1 : 0
          },
          route: "/coach",
          module: "coach"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-status", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      let form = this.$refs.coachForm.form
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.loading = true
          this.$store
            .dispatch("global/update", {
              payload: {
                id: form.id,
                id_academy: form.id_academy,
                id_carnet_type: form.id_carnet_type,
                first_name: form.first_name,
                last_name: form.last_name,
                dni: form.dni,
                address: form.address,
                gender: form.gender,
                phone: form.phone,
                email: form.email,
                instagram: form.instagram,
                profession: form.profession,
                url_profile_picture: form.url_profile_picture,
                url_dni_picture: form.url_dni_picture,
                url_carnet_picture: form.url_carnet_picture
              },
              route: "/coach/update",
              module: "coach"
            })
            .then((response) => {
              notify().then(({ notification }) => {
                notification("master-update", response.code, this.$snotify)
              })
              if ([200, 201].includes(response.code))
                this.$router.push("/entrenadores")
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        }
      })
    },

    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente al entrenador.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.$refs.coachForm.form.id,
                  status: 2
                },
                route: "/coach",
                module: "coach"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/entrenadores")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/coach",
          module: "coach",
          id: this.$route.params.id
        })
        .then((response) => {
          this.form = response.data
          this.$refs.coachForm.form = this.form
          this.$refs.coachForm.visible =
            response.data && response.data.active === 1 ? true : false
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
