var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 mr-3"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('first_name')
                ? _vm.errors.first('first_name')
                : '',"variant":_vm.showValidation && _vm.errors.first('first_name') ? 'danger' : '',"label":'Nombres'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:200'),expression:"'required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":"first_name","placeholder":"Ingrese los nombres","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('first_name') ? 'danger' : ''},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('last_name')
                ? _vm.errors.first('last_name')
                : '',"variant":_vm.showValidation && _vm.errors.first('last_name') ? 'danger' : '',"label":'Apellidos'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:200'),expression:"'required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":"last_name","placeholder":"Ingrese los apellidos","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('last_name') ? 'danger' : ''},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('dni') ? _vm.errors.first('dni') : '',"variant":_vm.showValidation && _vm.errors.first('dni') ? 'danger' : '',"label":'Cédula de identidad'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|numeric'),expression:"'required|numeric'"}],attrs:{"data-vv-validate-on":"input","name":"dni","placeholder":"Ingrese el número de cedula de identidad","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('dni') ? 'danger' : ''},model:{value:(_vm.form.dni),callback:function ($$v) {_vm.$set(_vm.form, "dni", $$v)},expression:"form.dni"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('address')
                ? _vm.errors.first('address')
                : '',"variant":_vm.showValidation && _vm.errors.first('address') ? 'danger' : '',"label":'Dirección'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"address","placeholder":"Ingrese la dirección","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('address') ? 'danger' : ''},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('email')
                ? _vm.errors.first('email')
                : '',"variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : '',"label":'Correo electrónico'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"data-vv-validate-on":"input","name":"email","placeholder":"Ingrese el correo electrónico","autocomplete":"off","disabled":_vm.loading,"type":"email","variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : ''},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('div',{staticClass:"flex flex-col md:flex-row items-end my-4"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('phone')
                  ? _vm.errors.first('phone')
                  : '',"variant":_vm.showValidation && _vm.errors.first('phone') ? 'danger' : '',"label":'Teléfono'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|phone'),expression:"'required|phone'"}],attrs:{"data-vv-validate-on":"input","name":"phone","placeholder":"Ingrese el teléfono","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('phone') ? 'danger' : ''},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('instagram')
                  ? _vm.errors.first('instagram')
                  : '',"variant":_vm.showValidation && _vm.errors.first('instagram') ? 'danger' : '',"label":'Instagram'}},[_c('t-input',{attrs:{"name":"instagram","placeholder":"Ingrese el usuario de Instagram","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('instagram') ? 'danger' : ''},model:{value:(_vm.form.instagram),callback:function ($$v) {_vm.$set(_vm.form, "instagram", $$v)},expression:"form.instagram"}})],1)],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('profession')
                ? _vm.errors.first('profession')
                : '',"variant":_vm.showValidation && _vm.errors.first('profession') ? 'danger' : '',"label":'Profesión'}},[_c('t-input',{attrs:{"name":"profession","placeholder":"Ingrese la profesión","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('profession') ? 'danger' : ''},model:{value:(_vm.form.profession),callback:function ($$v) {_vm.$set(_vm.form, "profession", $$v)},expression:"form.profession"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_carnet_type')
                ? _vm.errors.first('id_carnet_type')
                : '',"variant":_vm.showValidation && _vm.errors.first('id_carnet_type') ? 'danger' : '',"label":'Tipo de carnet'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"id_carnet_type","placeholder":"Seleccione una opción","disabled":_vm.loading,"textAttribute":"name","valueAttribute":"id","options":_vm.carnetTypes,"variant":_vm.showValidation && _vm.errors.first('id_carnet_type')
                  ? 'danger'
                  : ''},model:{value:(_vm.form.id_carnet_type),callback:function ($$v) {_vm.$set(_vm.form, "id_carnet_type", $$v)},expression:"form.id_carnet_type"}})],1)],1),_c('div',{staticClass:"w-full md:w-1/2 mx-1 relative",class:_vm.deletable ? 'pb-10' : ''},[_c('div',{staticClass:"flex flex-col md:flex-row items-end justify-around my-2"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-0 md:pr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_profile_picture')
                  ? _vm.errors.first('url_profile_picture')
                  : '',"variant":_vm.showValidation && _vm.errors.first('url_profile_picture')
                  ? 'danger'
                  : '',"label":'Foto del entrenador'}},[_c('cropper-upload',{attrs:{"file":_vm.form.url_profile_picture,"externalUrl":typeof _vm.form.url_profile_picture === 'string'
                    ? _vm.form.url_profile_picture
                    : null,"title":"Foto del entrenador","aspectRatio":3/4,"height":"250px","backgroundColor":"bg-white"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_profile_picture", $event)}}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-0 md:pr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_dni_picture')
                  ? _vm.errors.first('url_dni_picture')
                  : '',"variant":_vm.showValidation && _vm.errors.first('url_dni_picture')
                  ? 'danger'
                  : '',"label":'Foto cédula'}},[_c('t-upload-file',{attrs:{"disabled":_vm.loading,"file":_vm.form.url_dni_picture,"externalUrl":typeof _vm.form.url_dni_picture === 'string'
                    ? _vm.form.url_dni_picture
                    : '',"backgroundColor":"bg-white","placeholder":"Selecciona una imagen","height":"250px","accept":"image/*"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_dni_picture", $event)}}})],1)],1),_c('t-input-group',{staticClass:"w-full md:mr-0 md:pr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_carnet_picture')
                ? _vm.errors.first('url_carnet_picture')
                : '',"variant":_vm.showValidation && _vm.errors.first('url_carnet_picture')
                ? 'danger'
                : ''}},[_c('label',{staticClass:"block text-left font-bold"},[_vm._v("Foto del carnet")]),_c('t-upload-file',{attrs:{"disabled":_vm.loading,"file":_vm.form.url_carnet_picture,"externalUrl":typeof _vm.form.url_carnet_picture === 'string'
                  ? _vm.form.url_carnet_picture
                  : '',"backgroundColor":"bg-white","placeholder":"Selecciona una imagen","height":"250px","accept":"image/*"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_carnet_picture", $event)}}})],1),_c('div',{staticClass:"flex flex-col md:flex-row items-end my-4"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('gender')
                  ? _vm.errors.first('gender')
                  : '',"variant":_vm.showValidation && _vm.errors.first('gender') ? 'danger' : '',"label":'Sexo'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"gender","placeholder":"Seleccione una opción","disabled":_vm.loading,"textAttribute":"name","valueAttribute":"id","options":_vm.genders,"variant":_vm.showValidation && _vm.errors.first('gender') ? 'danger' : ''},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_academy')
                  ? _vm.errors.first('id_academy')
                  : '',"variant":_vm.showValidation && _vm.errors.first('id_academy') ? 'danger' : '',"label":'Equipo'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"id_academy","placeholder":"Seleccione una opción","disabled":_vm.loading,"textAttribute":"name","valueAttribute":"id","options":_vm.academies,"variant":_vm.showValidation && _vm.errors.first('id_academy') ? 'danger' : ''},model:{value:(_vm.form.id_academy),callback:function ($$v) {_vm.$set(_vm.form, "id_academy", $$v)},expression:"form.id_academy"}})],1)],1),_c('div',{staticClass:"flex justify-start my-4"},[_c('p',[_vm._v("Mostrar en la app")]),_c('t-toggle',{staticClass:"mx-3",attrs:{"disabled":_vm.loading},on:{"click":_vm.toggleVisibility},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}}),_c('p',[_vm._v("Sí")])],1),(_vm.deletable)?_c('div',{staticClass:"absolute bottom-0 w-full flex justify-center"},[_c('t-button',{attrs:{"type":"button","variant":"error"},on:{"click":_vm.deleteElement}},[_vm._v("Eliminar entrenador")])],1):_vm._e()],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }