<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('first_name')
                  ? errors.first('first_name')
                  : ''
              "
              :variant="
                showValidation && errors.first('first_name') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Nombres'"
            >
              <t-input
                v-validate="'required|max:200'"
                data-vv-validate-on="input"
                v-model="form.first_name"
                name="first_name"
                placeholder="Ingrese los nombres"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('first_name') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('last_name')
                  ? errors.first('last_name')
                  : ''
              "
              :variant="
                showValidation && errors.first('last_name') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Apellidos'"
            >
              <t-input
                v-validate="'required|max:200'"
                data-vv-validate-on="input"
                v-model="form.last_name"
                name="last_name"
                placeholder="Ingrese los apellidos"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('last_name') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('dni') ? errors.first('dni') : ''
              "
              :variant="showValidation && errors.first('dni') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Cédula de identidad'"
            >
              <t-input
                v-validate="'required|numeric'"
                data-vv-validate-on="input"
                v-model="form.dni"
                name="dni"
                placeholder="Ingrese el número de cedula de identidad"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="showValidation && errors.first('dni') ? 'danger' : ''"
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('address')
                  ? errors.first('address')
                  : ''
              "
              :variant="
                showValidation && errors.first('address') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Dirección'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.address"
                name="address"
                placeholder="Ingrese la dirección"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('address') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('email')
                  ? errors.first('email')
                  : ''
              "
              :variant="showValidation && errors.first('email') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Correo electrónico'"
            >
              <t-input
                v-validate="'required|email'"
                data-vv-validate-on="input"
                v-model="form.email"
                name="email"
                placeholder="Ingrese el correo electrónico"
                autocomplete="off"
                :disabled="loading"
                type="email"
                :variant="
                  showValidation && errors.first('email') ? 'danger' : ''
                "
              />
            </t-input-group>

            <div class="flex flex-col md:flex-row items-end my-4">
              <t-input-group
                :feedback="
                  showValidation && errors.first('phone')
                    ? errors.first('phone')
                    : ''
                "
                :variant="
                  showValidation && errors.first('phone') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Teléfono'"
              >
                <t-input
                  v-validate="'required|phone'"
                  data-vv-validate-on="input"
                  v-model="form.phone"
                  name="phone"
                  placeholder="Ingrese el teléfono"
                  autocomplete="off"
                  :disabled="loading"
                  type="text"
                  :variant="
                    showValidation && errors.first('phone') ? 'danger' : ''
                  "
                />
              </t-input-group>

              <t-input-group
                :feedback="
                  showValidation && errors.first('instagram')
                    ? errors.first('instagram')
                    : ''
                "
                :variant="
                  showValidation && errors.first('instagram') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Instagram'"
              >
                <t-input
                  v-model="form.instagram"
                  name="instagram"
                  placeholder="Ingrese el usuario de Instagram"
                  autocomplete="off"
                  :disabled="loading"
                  type="text"
                  :variant="
                    showValidation && errors.first('instagram') ? 'danger' : ''
                  "
                />
              </t-input-group>
            </div>

            <t-input-group
              :feedback="
                showValidation && errors.first('profession')
                  ? errors.first('profession')
                  : ''
              "
              :variant="
                showValidation && errors.first('profession') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Profesión'"
            >
              <t-input
                v-model="form.profession"
                name="profession"
                placeholder="Ingrese la profesión"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('profession') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('id_carnet_type')
                  ? errors.first('id_carnet_type')
                  : ''
              "
              :variant="
                showValidation && errors.first('id_carnet_type') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Tipo de carnet'"
            >
              <t-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_carnet_type"
                name="id_carnet_type"
                placeholder="Seleccione una opción"
                :disabled="loading"
                textAttribute="name"
                valueAttribute="id"
                :options="carnetTypes"
                :variant="
                  showValidation && errors.first('id_carnet_type')
                    ? 'danger'
                    : ''
                "
              ></t-select>
            </t-input-group>
          </div>

          <div
            class="w-full md:w-1/2 mx-1 relative"
            :class="deletable ? 'pb-10' : ''"
          >
            <div
              class="flex flex-col md:flex-row items-end justify-around my-2"
            >
              <t-input-group
                :feedback="
                  showValidation && errors.first('url_profile_picture')
                    ? errors.first('url_profile_picture')
                    : ''
                "
                :variant="
                  showValidation && errors.first('url_profile_picture')
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:mr-0 md:pr-2"
                :label="'Foto del entrenador'"
              >
                <cropper-upload
                  :file.sync="form.url_profile_picture"
                  :externalUrl="
                    typeof form.url_profile_picture === 'string'
                      ? form.url_profile_picture
                      : null
                  "
                  title="Foto del entrenador"
                  :aspectRatio="3/4"
                  height="250px"
                  backgroundColor="bg-white"
                />
              </t-input-group>

              <t-input-group
                :feedback="
                  showValidation && errors.first('url_dni_picture')
                    ? errors.first('url_dni_picture')
                    : ''
                "
                :variant="
                  showValidation && errors.first('url_dni_picture')
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:mr-0 md:pr-2"
                :label="'Foto cédula'"
              >
                <t-upload-file
                  :disabled="loading"
                  :file.sync="form.url_dni_picture"
                  :externalUrl="
                    typeof form.url_dni_picture === 'string'
                      ? form.url_dni_picture
                      : ''
                  "
                  backgroundColor="bg-white"
                  placeholder="Selecciona una imagen"
                  height="250px"
                  accept="image/*"
                />
              </t-input-group>
            </div>

            <t-input-group
              :feedback="
                showValidation && errors.first('url_carnet_picture')
                  ? errors.first('url_carnet_picture')
                  : ''
              "
              :variant="
                showValidation && errors.first('url_carnet_picture')
                  ? 'danger'
                  : ''
              "
              class="w-full md:mr-0 md:pr-2 my-4"
            >
              <label class="block text-left font-bold">Foto del carnet</label>
              <t-upload-file
                :disabled="loading"
                :file.sync="form.url_carnet_picture"
                :externalUrl="
                  typeof form.url_carnet_picture === 'string'
                    ? form.url_carnet_picture
                    : ''
                "
                backgroundColor="bg-white"
                placeholder="Selecciona una imagen"
                height="250px"
                accept="image/*"
              />
            </t-input-group>

            <div class="flex flex-col md:flex-row items-end my-4">
              <t-input-group
                :feedback="
                  showValidation && errors.first('gender')
                    ? errors.first('gender')
                    : ''
                "
                :variant="
                  showValidation && errors.first('gender') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Sexo'"
              >
                <t-select
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.gender"
                  name="gender"
                  placeholder="Seleccione una opción"
                  :disabled="loading"
                  textAttribute="name"
                  valueAttribute="id"
                  :options="genders"
                  :variant="
                    showValidation && errors.first('gender') ? 'danger' : ''
                  "
                ></t-select>
              </t-input-group>

              <t-input-group
                :feedback="
                  showValidation && errors.first('id_academy')
                    ? errors.first('id_academy')
                    : ''
                "
                :variant="
                  showValidation && errors.first('id_academy') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Equipo'"
              >
                <t-select
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.id_academy"
                  name="id_academy"
                  placeholder="Seleccione una opción"
                  :disabled="loading"
                  textAttribute="name"
                  valueAttribute="id"
                  :options="academies"
                  :variant="
                    showValidation && errors.first('id_academy') ? 'danger' : ''
                  "
                ></t-select>
              </t-input-group>
            </div>

            <div class="flex justify-start my-4">
              <p>Mostrar en la app</p>
              <t-toggle
                class="mx-3"
                @click="toggleVisibility"
                v-model="visible"
                :disabled="loading"
              />
              <p>Sí</p>
            </div>

            <div
              class="absolute bottom-0 w-full flex justify-center"
              v-if="deletable"
            >
              <t-button type="button" variant="error" @click="deleteElement"
                >Eliminar entrenador</t-button
              >
            </div>
          </div>
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {},
      showValidation: false,
      visible: false,
      carnetTypes: []
    }
  },
  computed: {
    academies() {
      return this.$store.getters["global/getItems"](
        "academy",
        "unpaginated_elements"
      )
    },
    genders() {
      return [
        {
          id: 1,
          name: "Femenino"
        },
        {
          id: 2,
          name: "Masculino"
        }
      ]
    }
  },
  methods: {
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          if (!this.form.url_profile_picture) {
            return this.$snotify.error(
              "La foto del entrenador es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
          }
          if (!this.form.url_dni_picture) {
            return this.$snotify.error(
              "La foto de la cédula es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
          }
          if (!this.form.url_carnet_picture) {
            return this.$snotify.error(
              "La foto del carnet es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
          }
          this.$emit("save")
        }
      })
    },
    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente al entrenador.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.form.id,
                  status: 2
                },
                route: "/coach",
                module: "coach"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/entrenadores")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {
    this.getItems("/academy/all", "academy", false)
    this.$store
      .dispatch("global/getItems", {
        route: "/carnet_type/all",
        noPaginate: true,
        isMaster: true,
        orderKey: "name",
        orderValue: "asc",
        params: {
          active: 1
        }
      })
      .then((response) => {
        this.carnetTypes = response.data
      })
      .catch((error) => {
        notify().then(({ notification }) => {
          notification("error", error.code, this.$snotify)
        })
      })
  }
}
</script>
